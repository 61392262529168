html,
body {
  height: 100%;
  margin: 0;
}

.App {
  text-align: center;
}
h1,
h2,
h3,
h4,
h5,
strong {
  color: #5299c7;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}
.page_text {
  text-align: left;
}
.App-header {
  background-color: #5299c7;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
}
.language_selector .btn-group {
  display: none;
}
.language_selector .btn-secondary {
  color: #5299c7;
  background-color: #ffffff;
  border-color: #6c757d;
  border-radius: 0;
  min-width: 102px;
  text-align: left;
}
.language_selector .btn-secondary:after {
  top: 54%;
  right: 10px;
}
.language_selector .btn-secondary:hover {
  color: #333f50;
  background-color: #5299c7;
}
.language_selector a.login {
  display: inline;
  margin-right: 14px;
  color: #fff;
}
.login_btn {
  background: none;
  border: none;
  color: #fff;
  margin-right: 6px;
}
.login_btn:hover {
  color: #535353;
}
.modal_container {
  width: 590px;
}
.modal_title_holder {
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
}
.MuiButton-containedPrimary {
  color: #fff;
  background-color: #5299c7 !important;
}
.login_btn:focus {
  outline: none !important;
}
.language_selector .dropdown {
  display: inline;
}
.language_selector {
  margin-top: 26px;
}
.gotolink a {
  color: #fff;
  text-transform: uppercase;
  background: #5299c7;
  font-size: 0.875rem;
  padding: 9px 16px;
}
.gotolink a:hover {
  text-decoration: none;
  color: #fff;
}
.App-link {
  color: #fff;
}
.carousel_fullwidth {
  max-width: 100%;
  height: 600px;
  background: black;
}
.carousel_fullwidth img {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.simulation_section {
  background-color: #5299c7;
  padding-bottom: 80px;
}
.simulation_form {
  margin-top: -98px;
}

.video-react .video-react-big-play-button {
  top: 54% !important;
  left: 48% !important;
  height: 60px !important;
  width: 60px !important;
  line-height: 56px !important;
  border-radius: 50% !important;
  background-color: #5299c7;
}
.MuiStepIcon-root {
  color: rgb(32, 37, 40) !important;
}
.MuiStepIcon-root.MuiStepIcon-active {
  color: #5299c7 !important;
}
.centered_text td {
  text-align: center !important;
}
.exon_paragraphe ul li {
  text-align: left;
}
.exon_paragraphe ul li span {
  font-weight: bold;
  color: #5299c7 !important;
}
.padding_top_checkbox {
  padding-top: 14px !important;
}
.padding_top_checkbox .MuiCheckbox-colorSecondary.Mui-checked {
  color: #5299c7 !important;
}
.MuiFormControlLabel-root {
  float: left;
}
.itemtitle {
  text-align: left;
  display: block;
}
.MuiFormControl-root {
  width: 100% !important;
}
.MuiSelect-select {
  text-align: left;
}
.btn_navigation {
  background-color: #5299c7 !important;
  color: #fff !important;
  border-radius: 3% !important;
  margin-bottom: 6px !important;
  min-width: 142px !important;
}
.btns_de_navigation {
  padding-top: 30px;
}
.margin_btn_right {
  margin-right: 8px !important;
}
.steps_container {
  background: #fff;
  padding: 30px 40px;
}
.steppers_title {
  padding-top: 30px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.card {
  border: none !important;
}
.titleHolder {
  max-width: 300px;
  margin: 0 auto;
}
.titleHolder:before {
  content: ' ';
  width: 300px;
  height: 1px;
  background-color: #5299c7;
  position: absolute;
  left: 118px;
  top: 48px;
}
.verticalMenuItems h4 {
  color: #afafaf !important;
}
.titleHolder:after {
  content: ' ';
  width: 300px;
  height: 1px;
  background-color: #5299c7;
  position: absolute;
  right: 118px;
  top: 48px;
}
.gridboxSection {
  background-color: #dfdfdf;
}
.gridboxSection .card {
  background-color: transparent;
}
.card-body {
  color: #5299c7;
}
.pageHeadDescription p {
  color: #535353;
}
.card-img {
  width: auto !important;
}
.secteurImage {
  cursor: pointer;
  margin: 0 auto;
  width: auto !important;
  max-height: 80px;
}
.imageGridHolder {
  height: 90px;
  display: table-cell;
  vertical-align: bottom;
}
.gridItemHolder {
  background-color: #d1d0d0;
  padding: 10px;
  margin-bottom: 30px;
}
.gridItemHolder .card-body {
  padding: 0px !important;
}
.gridboxSection .card-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.2em;
  color: #515151;
  height: 69px;
  vertical-align: middle;
  display: table-cell;
}
.card-body a:hover {
  text-decoration: none;
}
.stepsDots {
  list-style: none;
  width: 280px;
  text-align: center;
  margin: 0 auto;
}
.stepsDots li:after {
  content: '';
  position: absolute;
  width: 64px;
  height: 1px;
  background: #d3d3d3;
  left: 21px;
  top: 12px;
}
.stepsDots li:last-child:after {
  display: none;
}
.stepsDots li {
  float: left;
  margin-right: 60px;
  position: relative;
}
.stepsDots li.activeDot {
  color: #222a35;
}
.stepsDots li:last-child {
  margin-right: 0px;
}
.secondgridboxSection {
  background: #5299c7;
}
.secondgridboxSection .card {
  background-color: transparent;
}
.secondgridboxSection .titleHolder .head_title {
  color: #fff !important;
}
.secondgridboxSection .titleHolder::before {
  content: ' ';
  width: 300px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  left: 118px;
  top: 36px;
}
.secondgridboxSection .titleHolder::after {
  content: ' ';
  width: 300px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  right: 118px;
  top: 36px;
}
.notify_msg {
  font-size: 13px;
}
.twoline {
  text-transform: uppercase;
}
.twoline h4 {
  margin-top: -6px;
  color: #535353 !important;
}
.twoline h4:hover {
  color: #5299c7 !important;
}
.twoline h5 {
  margin-bottom: 0;
  font-size: 14px;
}
.app_footer {
  background-color: #464646;
  padding-top: 60px;
}
.footerBottom {
  background-color: #2e2e2e;
  padding-top: 8px;
}
.footerBottom p {
  color: #afafaf;
  margin-bottom: 8px;
  font-size: 12px;
}
.verticalMenuItems {
  text-align: left;
}
.verticalMenuItems h4 {
  text-transform: uppercase;
  color: #5299c7;
  font-size: 1.2em;
  margin: 0;
}
.left_align_it {
  text-align: left !important;
}
.verticalMenuItems ul {
  list-style: none;
  padding: 0;
}
.verticalMenuItems li {
}
.verticalMenuItems li a {
  color: #fff;
  font-size: 14px;
}
.verticalMenuItems li a:hover {
  text-decoration: none;
}
.verticalMenuItems li a span {
  font-size: 12px;
  position: relative;
  bottom: -1px;
  margin-left: -2px;
  margin-right: 6px;
}
.logoFooter {
  margin-top: 40px;
  width: 100%;
}
.footer_p {
  color: #fff;
  margin-bottom: 2px;
}
.footer_psub {
  color: #fff;
  font-size: 8px;
}
.show_inputfield {
  display: none !important;
}
.btn-prev {
  margin-right: 4px;
}
.btn-next {
  margin-left: 4px;
}
.btn_navigation a {
  color: #fff;
}
.btn_navigation a:hover {
  color: #fff;
}
.inner_page .App-header {
  position: relative;
}
.header_page {
  min-height: 300px;
  background: url('assets/images/page_header.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.page_section_title {
}
.page_section_title h3 {
  text-transform: uppercase;
  color: #fff;
}
.page_header {
  display: table;
  height: 300px;
  width: 100%;
}
.page_section_title {
  display: table-cell;
  vertical-align: middle;
}
.resultContainer {
  background: #000000a8;
}
.bottomTitle h3 {
  width: 300px;
  margin: 0 auto;
  font-size: 20px;
  background-color: #222a35;
  color: #fff;
  padding: 6px;
}
.bottomTitle h3 a,
.bottomTitle h3 a:hover {
  color: #fff;
  text-decoration: none;
}
.wrapper {
  display: flex;
  align-items: stretch;
}

#sidebar {
  min-width: 300px;
  max-width: 300px;
  min-height: 100vh;
}

#sidebar.active {
  margin-left: -300px;
}
a[data-toggle='collapse'] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
.input-feedback {
  color: red;
  font-size: 0.8em;
}
.submit_btn button {
  height: 34px;
}
.progress_loader {
  text-align: center;
  margin-top: -29px;
}
.user_avatar {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  display: block;
  font-weight: bold;
}
.navbar-nav .logoutbox button {
  color: #000;
}
.centering_item {
  text-align: center;
  display: block;
}
.submit_btn {
  margin-bottom: 14px;
}
.login_notification {
  color: #28a745 !important;
}
.getconnected {
  background: #59ac4f !important;
  padding: 2px 6px !important;
  font-size: 12px !important;
  margin-left: 8px !important;
}
h4.loading_text {
  text-align: center;
  text-transform: uppercase;
  margin-top: 40px;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #5fa0cb !important;
}
.innerBlockboxContainer .titleHolder {
  text-align: center;
  text-transform: uppercase;
}
.innerBlockboxContainer .card {
  background-color: transparent;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #a6aeb5;
  border-color: #6c757d;
  cursor: not-allowed;
}
.updatebutton.btn-secondary {
  color: #fff;
  background-color: #5299c7;
  border-color: #5299c7;
  height: 38px;
  width: 126px;
}
.progress_loader {
  margin-top: 0;
}
.progress_loader .MuiCircularProgress-colorPrimary {
  color: #fff;
}
.loader_bleu .progress_loader .MuiCircularProgress-colorPrimary {
  color: #242c3c !important;
}
.btn-primary {
  color: #fff;
  background-color: #5299c7 !important;
  border-color: #5a9dc9 !important;
}
.floating_btn {
  text-align: right;
}
.removeBtn {
  margin-left: 110px !important;
}
.cancelBtn {
  margin-left: 70px !important;
}
.picked_user {
  font-size: 0.9em;
}
.pagination_container {
  margin: 0 auto;
}
.pagination_container ul li {
  min-width: 26px;
  text-align: center;
  background: #e4e7e9;
  margin: 2px;
  border: 1px solid #242d3c;
  list-style: none;
  display: inline-block;
  cursor: pointer;
}
.pagination_container ul li.active {
  color: #fff;
  background-color: #242d3c;
  cursor: none;
}
.pagination_container ul li.disabled {
  display: none;
}
.pageinner {
  padding-top: 40px;
  padding-bottom: 40px;
}
.loading_circle {
  display: block;
  width: 200px;
  margin: 30px auto;
}
@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
  }
  #sidebar.active {
    margin-left: 0;
  }
}
#sidebar {
  /* don't forget to add all the previously mentioned styles here too */
  background: #242d3c;
  color: #727b86;
  transition: all 0.3s;
}
#sidebar a {
  color: #727b86;
}
#sidebar .sidebar-header {
  padding: 20px;
  text-align: center;
  background-color: #767171;
}
.header_subtitle {
  font-size: 0.9em;
  color: #fff;
}
.header_logo {
  display: block;
  padding-top: 10px;
}
#sidebar ul.components {
  padding: 20px 0;
}

#sidebar ul p {
  color: #727b86;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}
#sidebar ul li a:hover {
  color: #727b86;
}

#sidebar ul li.active > a,
a[aria-expanded='true'] {
  color: #fff;
  background: #242d3c;
}
#content {
  width: 100%;

  background: #f0f3f6;
  min-height: 100vh;
  transition: all 0.3s;
}
.searchbox {
  padding: 20px;
}
.sidebar_title label {
  padding-left: 14px;
}
.components a {
  text-decoration: none;
  color: #fff;
  font-size: 14px !important;
}
.components a:hover {
  text-decoration: none;
  color: #fff !important;
  background: #ffffff66 !important;
}
.components .active a {
  background: #ffffff66 !important;
}
.inner_content {
  padding: 20px;
}
.exporter {
  margin-left: 8px;
}
.margintop40 {
  margin-top: 40px;
}
.my_account {
  color: #fff;
  text-decoration: none;
  background: none;
  padding-top: 10px;
  display: block;
  margin-top: 22px;
}
.my_account:hover {
  color: #535353;
  text-decoration: none;
}
.page_heading {
  padding: 16px 110px;
  font-size: 28px;
  line-height: 42px;
}
.table_title {
  text-transform: uppercase;
  font-size: 1.3em;
  color: #5299c7;
}
.table_result tr td {
  height: 55px;
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
}
.circle_icon {
  width: 30px;
  line-height: 29px;
  height: 30px;
  background: #00000073;
  display: inline-block;
  color: #fff;
  text-align: center;
  margin-right: 8px;
  border-radius: 50%;
}
.final_result {
  color: #5299c7;
  font-weight: bold;
  text-align: center !important;
}
.offer_td_span_desc {
  display: block;
  text-align: center;
  text-transform: none;
  font-size: 12px;
}
.bigtable_title {
  text-transform: uppercase;
  background: #5299c7;
  color: #fff;
  font-size: 1.4em;
}
.td_value_final {
  font-weight: bold;
  color: #5299c7;
}
.anapec_chart {
  margin: 0 auto;
  max-width: 650px;
}
.anapec_chart td {
  text-align: center !important;
}
.anapec_chart th {
  text-align: left !important;
  color: #736d6d;
}
.footer_bottom {
  text-transform: none;
}
.page_text {
  max-width: 820px;
  text-align: justify;
  margin: 0 auto;
}
.home_bg {
  height: auto;
  width: 100%;
}
.slider-title {
  position: absolute;
  top: 120px;
  width: 100%;
}
.slider-title h4 {
  color: #464646;
  max-width: 600px;
  margin: 28px auto 0;
  font-size: 1.8em;
}
.orderedlist_alpha {
  list-style: lower-alpha;
}
b {
  display: block;
}
.offre_title {
  margin-bottom: 0px;
  display: block;
  margin-top: -4px;
}
.offre_title_nd {
  display: block;
  font-size: 10px;
  color: #000;
  margin-bottom: -6px;
  margin-top: -4px;
}
.exon_paragraphe {
  text-align: left;
}
.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.MuiCircularProgress-colorSecondary {
  color: #5299c7 !important;
}
.errorsection {
  text-align: left;
  color: #ff0000;
}
@media (max-width: 480px) {
  .header_logo {
  }
  .language_selector {
    display: none;
  }
}
